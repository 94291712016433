<template>
  <div>
    <a-card title="报警联系人">
      <div slot="extra">
          <a-popover placement="bottom">
              <template slot="content">
                  1、偶发一次超标为“波动”，连续5个监测周期持续超标为“异常”<br>
                  2、发生“异常”后，立即发送报警短信至该水厂报警联系人<br>
                  3、该报警持续1天未恢复，发送报警短信至该乡镇报警联系人<br>
                  4、该报警持续2天未恢复，发送报警短信至该区县报警联系人<br>
                  5、该报警持续3天未恢复，发送报警短信至该市级报警联系人<br>
                  注：当前监测周期为5-10分钟；非正常设备的监测指标视为“无效”
              </template>
              <template slot="title">
                  <span>报警规则</span>
              </template>
              <a-button type="default" icon="exclamation-circle" style="">报警规则</a-button>
          </a-popover>

          <a-button type="primary" icon="plus-circle" style="margin-left: 10px;" @click="add">添加联系人</a-button>
      </div>
        <div style="display: flex;" v-if="data.length > 0">
            <a-card v-for="(item,index) in data" :key="index" :title="item.name" style="width: 220px;margin-right: 10px;">
                <div slot="extra">
                    <a-icon type="delete" style="font-size: 18px;" @click="delConfirm(item)"/>
                </div>
                <div>
                    <span>{{item.tel}}</span>
                    <a-icon type="edit" style="float: right;font-size: 18px;" @click="edit(item)"/>
                </div>
            </a-card>
        </div>
        <div v-else style="min-height: 700px;">
            <a-empty></a-empty>
        </div>
    </a-card>
      <a-modal
              :title="isEdit ? '编辑' : '添加'"
              :width="640"
              :maskClosable="false"
              :visible="visible"
              :confirmLoading="confirmLoading"
              @ok="handleSubmit"
              @cancel="handleCancel"
      >
          <a-spin :spinning="confirmLoading">
              <a-form :form="form">
                  <a-form-item
                          label="名字"
                          :labelCol="labelCol"
                          :wrapperCol="wrapperCol"
                  >
                      <a-input placeholder="请输入名字" v-decorator="['name', { rules: [{required: true, message: '请输入名字'}]}]" />
                  </a-form-item>
                  <a-form-item
                          label="手机号"
                          :labelCol="labelCol"
                          :wrapperCol="wrapperCol"
                  >
                      <a-input placeholder="请输入手机号" v-decorator="['tel', { rules: [{required: true, message: '请输入手机号'}]}]" />
                  </a-form-item>

              </a-form>
          </a-spin>
      </a-modal>
  </div>
</template>

<script>
import {
  getAreaParam,
  contactAll,
  addContact,
  updateContact,
  deleteContact
} from "@/api/monitor/index";
export default {
  name: "contacts",
  data(){
    return {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13 }
      },
      form: this.$form.createForm(this),
      confirmLoading:false,
      data:[],
      visible:false,
      isEdit:false,
      areaParam:null,
      record: null,
      id: null,
    }
  },
  created(){
    let param = getAreaParam();
    this.areaParam = param
    console.log(param)
    this.fetchList()
  },
  mounted(){

  },
  methods:{
    fetchList(){
      contactAll(this.areaParam).then(r=>{
        this.data = r.data
      })
    },
    add () {
      this.visible = true
      this.isEdit = false
      this.form.resetFields()
    },
    edit (record) {
      this.id = record.id
      this.record = record
      this.visible = true
      this.isEdit = true
      const { form: { setFieldsValue } } = this
      this.$nextTick(() => {
        setFieldsValue({ ...record })
      })
    },
    delConfirm(item){
      const self = this
      this.$confirm({
        title: '确认删除',
        content: `是否确认删除当前联系人？`,
        cancelText: '取消',
        okText: '确定',
        okType: 'danger',
        onOk () {
          return new Promise((resolve, reject) => {
            deleteContact({ id: item.id }).then(response => {
              self.$message.error('删除成功')
              self.fetchList()
            }).catch((e) => {
            }).finally(() => {
              resolve()
            })
          })
        }
      })
    },
    handleSubmit () {
      const { form: { validateFields } } = this
      this.confirmLoading = true
      validateFields((errors, values) => {
        if (!errors) {
          console.log('values', values)
          console.log('record', this.record)
          if (this.isEdit) {
            const param = Object.assign({}, this.record, values)
            updateContact(param).then(r => {
              values.isEdit = this.isEdit
              this.$emit('ok', values)
              this.visible = false
              this.fetchList()
            }).catch(() => {
            }).finally(() => {
              this.confirmLoading = false
            })
          } else {
            const param = Object.assign({},this.areaParam,values)
            addContact(param).then(r => {
              values.isEdit = this.isEdit
              this.$emit('ok', values)
              this.visible = false
              this.fetchList()
            }).catch(() => {
            }).finally(() => {
              this.confirmLoading = false
            })
          }
        } else {
          this.confirmLoading = false
        }
      })
    },
    handleCancel () {
      this.visible = false
    },
  }
};
</script>

<style scoped></style>
